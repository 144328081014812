import React from "react";

import Page from "../components/layout/Page";
import Layout from "../components/layout/Layout";
import useStoryblok from "../utils/storyblok";
import Button from "../components/common/Button";
import { StoreContextProvider } from "../hooks/contexts";

export default function Page404() {
    const story = useStoryblok(null);

    let content = <h1>Not found</h1>;
    if (story && story.content) content = <Page blok={story.content} />;

    return (
        <StoreContextProvider>
            <Layout>
                <div data-scroll-section='true' className='-mt-1 w-full h-screen overflow-hidden relative bg-liminal-blue-medium'>
                    <div className=' w-full h-full relative'>
                        <div className='w-full h-full flex justify-center items-center absolute top-0'>
                            <div className='container grid grid-cols-6 phablet:grid-cols-12   gap-x-2 laptop:gap-x-4 large:gap-x-8   '>
                                <div className='hidden phablet:col-span-1 phablet:block' />

                                <div className='col-span-6 phablet:col-span-12 laptop:col-span-10    grid grid-cols-6 phablet:grid-cols-10   gap-x-2 laptop:gap-x-4 large:gap-x-8   text-liminal-white tracking-wide text-left py-8 uppercase'>
                                    <h1 className='block phablet:block col-span-1 phablet:col-span-2'>&nbsp;</h1>
                                    <h1 className='col-span-5 phablet:col-span-8  laptop:col-span-8 overflow-hidden'>Oops!</h1>
                                    <h1 className='col-span-6 phablet:col-span-12  laptop:col-span-10'>Nothing to see here.</h1>
                                </div>
                                <div className='hidden phablet:col-span-1 phablet:block' />
                                <div
                                    className={
                                        " col-span-5 col-start-2 phablet:col-span-7 phablet:col-start-6 laptop:col-start-7  laptop:col-span-5 large:col-start-7  large:col-span-4 rich-text-block"
                                    }>
                                    {/* <div className={'w-full text-liminal-white'}><AnimateInSplitLines text={''} start={true} delay={0} /></div> */}
                                    <Button hasMargin={true} link={"/"} text={"Back to home"} isPrimary={true} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        </StoreContextProvider>
    );
}
